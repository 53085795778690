<template>
    <span>
    <button class="button button--icon" @click="offcanvasShow = true"><svg class="icon" width="22" height="22"><use xlink:href="#edit" /></svg></button>
    <UiOffCanvas :offcanvas="offcanvasShow" :offcanvasShow.sync="offcanvasShow">

        <SuccessAlert/>
        <template v-slot:header>
            <h4>Modifica {{ utente.username }} </h4>
        </template>

            <template v-slot:form>
                <template v-if="!loading">
                <ErrorsAlert />
                <div class="form">

                    <div class="field-group flex" v-if="utente.tipo != 'ADMIN'">
                        <div class="field col col-12">
                        <label>Username</label>
                        <input type="text" v-model="username">
                        </div>
                    </div>

                    <div class="field-group flex">
                        <div class="field col col-12">
                        <label>Nome e cognome</label>
                        <input type="text" v-model="nome">
                        </div>
                    </div>
       
                    <div class="field-group flex">
                        <div class="field col col-12">
                        <label>Email</label>
                        <input type="text" v-model="email">
                        </div>
                    </div>

                    <div class="field-group flex">
                        <div class="field col col-12">
                        <label>Attivo</label>
                        <label class="switch">
                            <input type="checkbox" v-model="attivo">
                            <span class="slider round"></span>
                        </label>
                        </div>
                    </div>
                    
                    <fieldset>
                        <h4>Modifica password</h4>
                        <div class="field-group flex">
                            
                            <div class="field col col-12">
                                <label>Password <small @click="generatePassword" v-if="tipo != 'ADMIN'" class="genera-password">Genera</small></label>
                                <input :type="showPassword ? 'text' : 'password'" v-model="password">
                                <div @click="mostraPassword">mostra password</div>
                            </div>

                            <div class="field col col-12">
                                <label>Conferma password</label>
                                <input :type="showPassword ? 'text' : 'password'" v-model="confermapassword">
                            </div>
                        </div>
                    </fieldset>
       
                    <div class="form--action">
                        <button class="button--delete" @click="modalShow = true">Elimina utente</button>
                        <button class="button button--small m-l-auto" @click.prevent="aggiornaUtente(utente)" :disabled="disableSaveButton">Salva</button>
                    </div>

                </div>

            </template>
                <template v-else>
                    <UiPreloader/>
                </template>
            </template>


     </UiOffCanvas>

     <UiModal :modal="modalShow" :modalShow.sync="modalShow">
            <template v-slot:header>
                <h4 class="modal--title">Sicuri di voler eliminare {{ utente.nome }}?</h4>
            </template>

            <template v-slot:form>
                    <template v-if="!loading">
                    <div class="form">
                    <ErrorsAlert />
                    <p>Digita <strong>ELIMINA</strong> nel campo sottostante per eliminare <strong>{{ utente.nome }}</strong>. <br/>Verranno eliminate tutti messaggi scritti dall'utente</p>
                    <div class="field-group flex">
                        <div class="field col col-12">
                            <input type="text" v-model="confermaEliminazione">
                        </div>
                    </div>
                    <div class="form--action">
                        <button class="button button--annulla" @click="modalShow = false">Annulla</button>     
                        <button class="button button--conferma" @click.prevent="eliminaUtente(utente)" :disabled="confermaEliminazione != 'ELIMINA'">Conferma ed elimina</button>
                    </div>
                    </div>
                    </template>
                    <template v-else>
                        <UiPreloader/>
                    </template>
            </template>
     </UiModal>

    </span>
</template>
<script>

import SuccessAlert from '@/components/SuccessAlert.vue'
import ErrorsAlert from '@/components/ErrorsAlert.vue'
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
    name: 'ModificaUtente',

    data() {
        return {
            id: '',
            form: '',
            disableSaveButton: false,
            offcanvasShow: false,
            tipo: this.utente.tipo,
            email: this.utente.email,
            username: this.utente.username,
            nome: this.utente.nome,
            attivo: this.utente.attivo,
            password: '',
            showPassword: false,
            confermapassword: '',
            loading: false,
            modalShow: false,
            confermaEliminazione: ''
        }
    },

    props: {
        utente: Object
    },

    watch: {

        confermapassword: function (val) {
           this.button = this.password == val
        }


    },
    components: {
        ErrorsAlert,
        SuccessAlert
    },

    methods: {
        ...mapActions('utenti',[
            'updateUtente',
            'deleteUtente'
        ]),

        mostraPassword() {
            this.showPassword = !this.showPassword
        },


        createPassword: function () {
        return Math.random().toString(36).slice(-6);
        },

         generatePassword: function () {
            this.showPassword = true
            this.password = this.createPassword();
         },

        aggiornaUtente () {
            this.loading = true
            this.disableSaveButton = true
            this.$store.state.success = null
            

            const form = {
                id:  this.utente.id,
                tipo: this.tipo,
                username: this.username.toLowerCase(),
                attivo: this.attivo,
                email: this.email,
                nome: this.nome
            }

            this.form = form

            if(this.password != '' && this.password == this.confermapassword) {
                Object.assign(form, {password: this.password});
            }

            this.updateUtente(this.form).then((response) => {
                this.offcanvasShow = false
                this.disableSaveButton = false
                this.$store.dispatch('utenti/fetchUtenti')
                this.loading = false
            }).catch(e => {
                this.loading = false
                this.$store.state.success = null
                this.$store.state.errors = e.response.data;
                this.disableSaveButton = false
            })
        },

        eliminaUtente (utente) {
            this.disableSaveButton = true
            this.deleteUtente(utente).then((response) => {
                this.offcanvasShow = false
                this.disableSaveButton = false
                this.$store.dispatch('utenti/fetchUtenti')
            }).catch(error => {
                console.log('Errore:'+error)
            })
        },

    }
}
</script>