<template>
  <div>
    <header>
      <h1 class="header--title">Utenti</h1>

        <div class="header--left">
          <button @click="utentiByType('')" :disabled="!tipo && !key ">Tutti</button>
          <button @click="utentiByType('INSEGNANTE')" :disabled="tipo == 'INSEGNANTE'">Insegnanti</button>
          <button @click="utentiByType('EDUCATORE')" :disabled="tipo == 'EDUCATORE'">Educatori</button>
          <button @click="utentiByType('ADMIN')" :disabled="tipo == 'ADMIN'">Admin</button>
          <button @click="utentiByType('CLASSE')" :disabled="tipo == 'CLASSE'">Classi</button>
        </div>

       <div class="header--right">
        
        <div class="searchContainer">
          <input type="text" placeholder="Cerca" @keyup="cercaUtenti()" v-model="key">
          <button @click="resetCercaUtenti()" v-if="key">x</button>
        </div>

        <NuovoUtente/>

       </div>

    </header>


    <table class="table" v-if="getUtenti">

              <tr class="table--header">
                  <th class="cell">
                    Username
                  </th>
                  <th class="cell">
                    Email
                  </th>
                  <th class="cell">
                    Nome Cognome
                  </th>
                  <th class="cell">
                    Tipo
                  </th>
                  <th class="cell">
                    Invio credenziali
                  </th>
                   <th class="cell">
                    Azioni
                  </th>
              </tr>

            <tr v-for="utente in getUtenti" :key="utente.id" class="row" :class="{ 'disabled' : utente.attivo == 0 }">
                <td class="cell">
                <strong>{{ utente.username }}</strong>
                </td>

                <td class="cell">
                {{ utente.email }}
                </td>

                <td class="cell">
                {{ utente.nome }}
                </td>

                <td class="cell">
                {{ utente.tipo }}
                </td>

                <td class="cell">
                  <template v-if="utente.tipo != 'CLASSE' && utente.tipo != 'ADMIN'">
                    <span v-if="utente.credential_mail_send_at">
                      <InvioCredenziali :utente="utente" :sent="true" />
                    </span>
                    <span v-else>
                      <InvioCredenziali :utente="utente" :sent="false" />
                    </span>
                  </template>
                </td>
         
                <td class="cell">
                    <div v-if="utente.tipo != 'CLASSE'">
                      <ModificaUtente :utente="utente"/>
                    </div>
                </td>
              
            </tr>
   </table>
   <div v-else>
        <UiPreloader/>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import NuovoUtente from '@/components/editor/NuovoUtente.vue'
import ModificaUtente from '@/components/editor/ModificaUtente.vue'
import InvioCredenziali from '@/components/editor/InvioCredenziali.vue'

import { mapGetters, mapState, mapActions } from 'vuex'
export default {
  name: 'EditorUtenti',

  data: () => {
    return {
     key: '',
     tipo: ''
    }
  },
  
  components: {
    NuovoUtente,
    ModificaUtente,
    InvioCredenziali
  },

  computed: {
    ...mapGetters([
      'isLogged',
      'isAdmin',
      'isEducatore',
      'isInsegnante',
      'isClasse',
      'user'
    ]),

    ...mapGetters('utenti',[
        'getUtenti',
        'getUtentiByType',
        'getUtentiSearch'
     ]),

  },

  methods: {
    ...mapActions('utenti',[
        'fetchUtenti',
        'inviaCredenziali'
    ]),

    utentiByType(tipo) {
      this.tipo = tipo,
      this.getUtentiByType(tipo)
    },

    cercaUtenti() {
      this.tipo = '',
      this.key = event.target.value,
      this.getUtentiSearch(event.target.value)
    },

    resetCercaUtenti() {
      this.tipo = '',
      this.key = '',
      this.getUtentiSearch('')
    },


    inviaCred(utente) {
        this.inviaCredenziali({
            id:  utente.id,
        }).then((response) => {
            this.nome = ''
            this.$store.dispatch('utenti/fetchUtenti')
        }).catch(e => {
            this.$store.state.success = null;
            this.$store.state.errors = e.response.data;
      })

    }
    
  },


  beforeMount() {
    this.$store.dispatch('utenti/fetchUtenti')
  },

}
</script>
