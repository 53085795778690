<template>
    <div>
       <button @click="inviaCred(utente)" class="button button--outline button--extrasmall" :class="{ 'button--success' : sent }" :disabled="sending">
           {{ text }} <span v-if="sending" class="p-relative m-l-4 m-r-4"><div class="spinner"></div></span>
       </button>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
    name: 'InvioCredenziali',

    data() {
        return {
            sending: false
        }
    },

    props: {
        sent: Boolean,
        utente: Object
    },

    computed: {
        text: function() {
            if(!this.sent) {
                return 'Invia';
            }
            return 'Reinvia';
        }
    },

    methods: {
    ...mapActions('utenti',[
        'inviaCredenziali'
    ]),

    inviaCred(utente) {
        this.sending = true
        this.inviaCredenziali({
            id:  utente.id,
        }).then((response) => {
            this.sending = false
            this.$store.state.success = 'Credenziali inviate!';
            this.$store.dispatch('utenti/fetchUtenti')
        }).catch(e => {
            this.sending = false
            this.$store.state.success = null;
            this.$store.state.errors = e.response.data;
      })

    }
    
  },
}
</script>