<template>
    <span>
    <button class="button button--small" @click="modalShow = true">Nuovo utente</button>
    <UiModal :modal="modalShow" :modalShow.sync="modalShow">
                <template v-slot:header>
                    <h4 class="modal--title">Nuovo utente</h4>
                </template>

                <template v-slot:form>
                    <template v-if="!loading">
                    <ErrorsAlert />

                    <div class="form">

                        <div class="field-group flex">
                          <div class="field col col-12">
                          <label>Tipo utente <span class="required">*</span></label>
                          <multiselect v-model="tipo"
                             :options="ruoli"
                             :searchable="false"
                             :allow-empty="false"
                             :show-labels="true"
                             placeholder="Scegli tipo utente"></multiselect>
                          </div>
                        </div>

                        <div class="field-group flex" v-if="tipo && tipo == 'ADMIN'">
                            <div class="field col col-12">
                            <label>Username</label>
                            <input type="text" v-model="username">
                            </div>
                        </div>

                        <div class="field-group flex" v-if="tipo">
                            <div class="field col col-12 col-sm-6">
                            <label>Nome</label>
                            <input type="text" v-model="nome">
                            </div>
                        </div>

                        <div class="field-group flex" v-if="tipo">
                            <div class="field col col-12 col-sm-6">
                            <label>Cognome</label>
                            <input type="text" v-model="cognome">
                            </div>
                        </div>

                        <div class="field-group flex" v-if="tipo">
                            <div class="field col col-12">
                            <label>Email</label>
                            <input type="text" v-model="email">
                            </div>
                        </div>

                        <div class="field-group flex" v-if="tipo">
                            <div class="field col col-12">
                            <label>Attivo</label>
                            <input type="checkbox" v-model="attivo">
                            </div>
                        </div>

                        <div class="field-group flex" v-if="tipo">
                            <div class="field col col-12 col-sm-6">
                            <label>Password <small @click="generatePassword" v-if="tipo != 'ADMIN'" class="genera-password">Genera</small></label>
                            <input :type="showPassword ? 'text' : 'password'" v-model="password">
                            <div @click="mostraPassword">mostra password</div>
                            </div>
                        </div>

                        <div class="field-group flex" v-if="tipo">
                            <div class="field col col-12 col-sm-6">
                            <label>Conferma password</label>
                            <input :type="showPassword ? 'text' : 'password'" v-model="confermapassword">
                            </div>
                        </div>

                        <div class="form--action">
                                <button class="button button--annulla" @click="modalShow = false">Annulla</button>
                                <button class="button button--conferma" @click.prevent="createUtente" v-if="tipo" :disabled="!button">Salva</button>                    
                       </div>

                    </div>   

                </template>
                <template v-else>
                <UiPreloader/>
                </template>
            </template>

     </UiModal>
    </span>
</template>
<script>

import ErrorsAlert from '@/components/ErrorsAlert.vue'
import { mapActions } from 'vuex'

export default {
    name: 'NuovoUtente',

    data() {
        return {           
            ruoli: [
                'ADMIN', 'EDUCATORE', 'INSEGNANTE'
            ],
            form: '',
            tipo: '',
            username: '',
            nome: '',
            cognome: '',
            email: '',
            attivo: 1,
            password: '',
            loading: false,
            showPassword: false,
            confermapassword: '',
            modalShow: false,
            button: false

        }
    },

    props: {

    },

    watch: {

        confermapassword: function (val) {
           this.button = this.password == val
        },

        tipo: function (val) {
            if(val != 'ADMIN') {
                this.password = this.createPassword(),
                this.showPassword = true
            }
        }

    },

    components: {
        ErrorsAlert
    },

    methods: {
    ...mapActions('utenti',[
        'addUtente'
    ]),

    mostraPassword() {
        this.showPassword = !this.showPassword
    },

    createPassword: function () {
      return Math.random().toString(36).slice(-6);
    },

    generatePassword: function () {
      this.password = this.createPassword();
    },

    createUtente () {
        this.loading = true
        if(this.tipo == 'ADMIN') {
            const form = {
                username: this.username,
                attivo: 1,
                nome: this.nome + ' ' + this.cognome,
                tipo: this.tipo,
                email: this.email.toLowerCase(),
                password: this.password
            }
            this.form = form
        }

        if(this.tipo == 'EDUCATORE' || this.tipo == 'INSEGNANTE') {
            const username = this.nome+'.'+this.cognome
            const form = {
                username: username.toLowerCase(), // corretto che sia email
                attivo: 1,
                nome: this.nome + ' ' + this.cognome,
                tipo: this.tipo,
                email: this.email,
                password: this.password
            }
            this.form = form
        }

        this.addUtente(this.form).then((response) => {
                this.nome = ''
                this.modalShow = false
                this.$store.dispatch('utenti/fetchUtenti')
                this.loading = false
        }).catch(e => {
                this.loading = false
                this.$store.state.success = null;
                this.$store.state.errors = e.response.data;
        })
    }

    }
}
</script>